import 'ol/ol.css';
import './RuviMap.css';

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get } from 'ol/proj';
import { getCenter } from 'ol/extent';
import React, { useRef, useEffect, useState } from 'react';
import { Map, View } from 'ol';
import { Zoom } from 'ol/control';
import { defaults } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import TileState from 'ol/TileState';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import * as olExtent from 'ol/extent';
import Point from 'ol/geom/Point';
import Cluster from 'ol/source/Cluster';
import Select from 'ol/interaction/Select';
import { Fill, Stroke, Circle, Style, Text } from 'ol/style';
import { click } from 'ol/events/condition';
import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import GeoLocationControl from './GeoLocationControl';
import * as RuviConstants from './RuviConstants';
import ServiceApi from './ServiceApi';

function RuviMap({history, i18n, capabilities, selvityspyynnot, selectedId, setSelectedId, selvityspyyntotyyppi, setCoordinates}) {

    const selectedIdRef = useRef(selectedId);
    const parcelFeaturesRef = useRef([]);
    const parcelsFeaturesRef = useRef([]);
    const selvityspyyntotyyppiRef = useRef(selvityspyyntotyyppi);
    const statusColorSelected = 'rgba(208,0,111,1.0)';
    const statusColorNotObserved = 'rgba(52,56,65,0.5)';
    const statusColorOngoing = 'rgba(247,207,61,0.7)';
    const statusColorObserved = 'rgba(72,199,116,0.7)';
    const statusColorSkipped = 'rgba(241,70,104,0.7)';
    const statusColorPl = 'rgba(128,195,164,0.3)';
    const colorCluster = 'rgba(52,56,65,0.5)';
    const colorClusterStroke = 'rgb(52,56,65)';
    const textColor = 'rgb(0,0,0)';

    // Register projection
    proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);

    const projExtent = [50199.4814, 6582464.0358, 761274.6247, 7799839.8902];

    const proj = get('EPSG:3067');
    if (proj) {
        proj.setExtent(projExtent);
    }

    /* ol-map -> */
    /*const osmLayer = new TileLayer({
        source: new OSM({
            crossOrigin: 'anonymous',
            wrapX: false
        })
    });*/
    /* <- ol-map */

    /* Ruvi-map -> */
    var parser = new WMTSCapabilities();
    var result = parser.read(capabilities);
    var options = optionsFromCapabilities(result, {
          layer: 'maastokartta',
          matrixSet: 'WGS84_Pseudo-Mercator',
          crossOrigin: window.location.origin
        });
    options.urls[0] = window.wmtsUrl +  '/wmts?';
    //console.log(options);

    var source = new WMTS(options);
    source.setTileLoadFunction((tile, src) => {
        ServiceApi.getTile(src)
        .then((dataUrl) => {
            tile.getImage().src = dataUrl;
        })
        .catch((error) =>  {
            tile.setState(TileState.ERROR);
        });
      });

    const osmLayer = new TileLayer({
        opacity: 1,
        source: source
      })

    /* <- Ruvi-map */

    const getParcelStatusColor = function (status) {
        if (status === null || status === undefined || status === 1) {
            return statusColorNotObserved;
        } else if (status === 2) {
            return statusColorOngoing;
        } else if (status === 3) {
            return statusColorObserved;
        } else if (status === 42) {
            return statusColorSkipped;
        } else if (status === 100) {
            return statusColorPl;
        }
    }

    const parcelFill = function (feature) {
        if (feature.get('statuskoodi') === 99) {
            return null
        }
        return new Fill({
            color: getParcelStatusColor(feature.get('statuskoodi'))
        })
    };

    const parcelStroke = function (feature, resolution) {
        return new Stroke({
            color: feature.get('id') === selectedIdRef.current ? statusColorSelected : 'rgba(52,56,65,1.0)',
            width: feature.get('id') === selectedIdRef.current ? (resolution < 1 ? 10 : 5) : (resolution < 1 ? 3 : 1)
        })
    }

    const parcelText = function (feature, resolution) {
        console.log(resolution)
        return new Text({
            text: ''+ feature.get('peruslohkonimi') + '\n' + feature.get('peruslohkotunnus') + ' ' + feature.get('kasvulohkotunnus'),
            fill: new Fill({
                color: textColor
            }),
            overflow: true,
            font: resolution < 1 ? '10px sans-serif' : '10px sans-serif'
        });
    };

    const parcelStyles = function (feature, resolution) {
        return new Style({
            image: new Circle({
                fill: parcelFill(feature),
                stroke: parcelStroke(feature, resolution),
                radius: 5
            }),
            fill: parcelFill(feature),
            stroke: parcelStroke(feature, resolution),
            text: parcelText(feature, resolution)
        })
    };

    const parcelSource = new VectorSource({
            loader: function (extent, resolution, projection) {
                console.log('parcelSource', parcelFeaturesRef.current)
                parcelSource.addFeatures(parcelFeaturesRef.current);
            }
        });

    const parcelLayer = new VectorLayer({
        source: parcelSource,
        style: parcelStyles,
        minZoom: 10,
        visible: true
    });

    const parcelsStyles = function (feature, resolution) {
        return new Style({
            image: new Circle({
                fill: parcelFill(feature),
                stroke: parcelStroke(feature, resolution),
                radius: 5
            }),
            fill: parcelFill(feature),
            stroke: parcelStroke(feature, resolution),
            //text: parcelText(feature, resolution)
        })
    };

    const parcelsSource = new VectorSource({
        loader: function (extent, resolution, projection) {
            console.log('parcelsSource', parcelsFeaturesRef.current)
            parcelsSource.addFeatures(parcelsFeaturesRef.current);
        }
    });

    const parcelsLayer = new VectorLayer({
        source: parcelsSource,
        style: parcelsStyles,
        minZoom: 10,
        visible: true
    });

    const parcelPointClusterSource = new Cluster({
        source: parcelSource,
        geometryFunction: function (feature) {
            if (feature.get('statuskoodi') !== 99) {
                let centerCoord = olExtent.getCenter(feature.getGeometry().getExtent());
                return new Point(centerCoord);
            } else {
                return null;
            }

        }
    });

    var styleCache = {};
    const parselPointClusteStyle = function (feature) {
        var size = feature.get('features').length;
        var style = styleCache[size];
        if (!style) {
            style = new Style({
                image: new Circle({
                    fill: new Fill({
                        color: colorCluster
                    }),
                    stroke: new Stroke({
                        color: colorClusterStroke
                    }),
                    radius: 14
                }),
                fill: new Fill({
                    color: colorCluster
                }),
                stroke: new Stroke({
                    color: colorClusterStroke
                }),
                text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                        color: textColor
                    })
                })
            });
            styleCache[size] = style;
        }
        return style;
    }

    const parcelPointLayer = new VectorLayer({
        source: parcelPointClusterSource,
        style: parselPointClusteStyle,
        maxZoom: 10,
        visible: true
    });

    // Interactions
    const selectParcelInteraction = new Select({
        layers: [parcelLayer],
        style: parcelStyles,
        condition: click
    });
    const selectParcelPointInteraction = new Select({
        layers: [parcelPointLayer]
    });

    // Init map instance
    const defaultInteractions = defaults({ altShiftDragRotate: false, pinchRotate: false /*, mouseWheelZoom: false*/ });
    defaultInteractions.push(selectParcelInteraction);
    defaultInteractions.push(selectParcelPointInteraction);

    let centerMap = null;
    let geoLocationTool = new GeoLocationControl(proj, (coords) => {centerMap(coords)}, (coords) => {setCoordinates(coords)})

    const [map] = useState(new Map({
        layers: [
            osmLayer,
            parcelPointLayer,
            parcelsLayer,
            parcelLayer,
        ],
        view: new View({
            projection: proj.getCode(),
            center: getCenter(projExtent),
            zoom: 12
        }),
        interactions: defaultInteractions,
        controls: [
            new Zoom(),
            geoLocationTool,
        ],
        moveTolerance: 3
    }));

    if (!centerMap) {
        centerMap = (coords) => {map.getView().setCenter(coords)}
        geoLocationTool.start();
    }

    useEffect(() => {
        console.log('refresh', selvityspyynnot);
        if (parcelFeaturesRef.current.length !== selvityspyynnot.length) {
            let parcelFeatures =[];
            let parcelsFeatures =[];
            let parser = new WKT()
            for (let index = 0; index < selvityspyynnot.length; index++) {
                const selvityspyynto = selvityspyynnot[index];
                if (selvityspyynto.klgeometria != null || selvityspyynto.plgeometria != null) {
                    var parcelFeature = new Feature();
                    parcelFeature.setGeometry(
                        parser.readGeometry(selvityspyynto.klgeometria ?
                            selvityspyynto.klgeometria :
                            selvityspyynto.plgeometria, {dataProjection : selvityspyynto.proj ? selvityspyynto.proj :'EPSG:3067', featureProjection : 'EPSG:3067'}));
                    parcelFeature.setId(selvityspyynto.id);
                    parcelFeature.setProperties({...selvityspyynto, geometria: null});    
                    parcelFeatures.push(parcelFeature); 
                    if (selvityspyynto.statuskoodi !== 99) {
                        var parcelsFeature = new Feature();
                        parcelsFeature.setGeometry(
                            parser.readGeometry(selvityspyynto.plgeometria, {dataProjection : selvityspyynto.proj ? selvityspyynto.proj :'EPSG:3067', featureProjection : 'EPSG:3067'}));
                        parcelsFeature.setId(-selvityspyynto.id);
                        parcelsFeature.setProperties({...selvityspyynto, geometria: null, statuskoodi: 100, id: -selvityspyynto.id});    
                        parcelsFeatures.push(parcelsFeature);  
                    }     
                }
            }
            console.log(parcelFeatures);
            parcelFeaturesRef.current = parcelFeatures;
            parcelsFeaturesRef.current = parcelsFeatures;
            if (parcelFeaturesRef.current.length !== 0) {
                let zoomToExtent = olExtent.createEmpty();
                parcelFeaturesRef.current.forEach((feature) => {
                    if (feature.get('statuskoodi') !== 99) {
                        const featureExtent = feature.getGeometry().getExtent();
                        olExtent.extend(zoomToExtent, featureExtent);
                    }
                });
                map.getView().fit(zoomToExtent, { duration: 500, padding: [100,100,100,100] });
            } 
        }
        map.getLayers().forEach(layer => {if (layer instanceof VectorLayer) layer.getSource().refresh()});
     }, [map, selvityspyynnot])

    useEffect(() => {
        const zoomAll = () => {
            if (parcelFeaturesRef.current.length !== 0) {
                let zoomToExtent = olExtent.createEmpty();
                parcelFeaturesRef.current.forEach((feature) => {
                    if (feature.get('statuskoodi') !== 99) {
                        const featureExtent = feature.getGeometry().getExtent();
                        olExtent.extend(zoomToExtent, featureExtent);
                    }
                    });
                map.getView().fit(zoomToExtent, { duration: 500, padding: [100,100,100,100] });
            }
        }

        const zoomToId = (selectedId) => {
            const view = map.getView();
            let zoomToExtent = olExtent.createEmpty();
            let lohko = parcelFeaturesRef.current.filter( f => f.getId() === selectedId);
            if (lohko && lohko[0]) {
                const featureExtent = lohko[0].getGeometry().getExtent();
                olExtent.extend(zoomToExtent, featureExtent);
                view.fit(zoomToExtent, { duration: 1000, padding: [100, 100, 100, 100] });
            }
        }
        console.log('updating', selectedId);
        if (selectedId !== -1) {
            zoomToId(selectedId);
        }
        else {
            zoomAll();
        }
        selectedIdRef.current = selectedId;
    }, [selectedId, map])

    useEffect(() => {
        selvityspyyntotyyppiRef.current = selvityspyyntotyyppi;
    }, [selvityspyyntotyyppi])

    // Mount
    useEffect(() => {
        console.log('map mount')
        function onParcelSelect(evt) {
            console.log(evt);
            let select = evt.selected[0]
            if (select && evt.selected[0].get('id')>0 ) {
                setSelectedId(evt.selected[0].get('id'));
                var a = document.createElement('a');
                switch (selvityspyyntotyyppiRef.current) {
                    case RuviConstants.RUVIOBSNOTANSWERED:
                        a.href = '#/obsrequest';
                        break;
                    case RuviConstants.RUVIOBSANSWERED:
                        a.href = '#/obsrequestsanswered';
                        break;
                    default:
                        a.href = '#/start';
                }
                a.click();
            }
            selectParcelInteraction.getFeatures().clear();
        };

        function onParcelPointSelect(evt) {
            let zoomToExtent = olExtent.createEmpty();
            evt.selected.forEach((clusterFeature) => {
                const actualFeatures = clusterFeature.get('features');
                actualFeatures.forEach((actualFeature) => {
                    const featureExtent = actualFeature.getGeometry().getExtent();
                    olExtent.extend(zoomToExtent, featureExtent);
                });
            });
            selectParcelPointInteraction.getFeatures().clear();
            map.getView().fit(zoomToExtent, { duration: 500, padding: [100,100,100,100] });
        };

        selectParcelPointInteraction.on('select', onParcelPointSelect);
        if (setSelectedId) {
            selectParcelInteraction.on('select', onParcelSelect);
        }
        map.setTarget('ruvi-map');

        if (parcelFeaturesRef.current.length !== 0) {
            let zoomToExtent = olExtent.createEmpty();
            if (parcelFeaturesRef.current.Features) {
                parcelFeaturesRef.current.Features.filter(feature => selectedIdRef.current === 0 || feature.get('id') === selectedIdRef.current)
                    .forEach((feature) => {
                        if (feature.get('statuskoodi') !== 99) {
                            const featureExtent = feature.getGeometry().getExtent();
                            olExtent.extend(zoomToExtent, featureExtent);
                        }
                    });
                map.getView().fit(zoomToExtent, { duration: 500, padding: [100, 100, 100, 100] });
            }
        }

        console.log('map mounted')

        return () => {
            console.log('map unmount')
            // Unmount
            selectParcelPointInteraction.un('select', onParcelPointSelect);
            selectParcelInteraction.un('select', onParcelSelect);
            map.setTarget(null);
            console.log('map unmounted')

        };
        // eslint-disable-next-line
    }, []);

    return (
        <div id='ruvi-map'>
        </div>
    );
}

export default RuviMap;
