import React from 'react';

class Dialogs {
    static Information = (title, contents, visible, setVisible) => {
        return <div className={`modal ${visible ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={() => setVisible(false)}></button>
                </header>
                <section className="modal-card-body">
                {contents}
                </section>
                <footer className="modal-card-foot">
                <button className="button is-link" onClick={() => setVisible(false)}>Sulje</button>
                </footer>
            </div>
        </div>;
    }

    static Confirmation = (title, contents, visible, setVisible, doAction) => {
        return <div className={`modal ${visible ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={() => setVisible(false)}></button>
                </header>
                <section className="modal-card-body">
                {contents}
                </section>
                <footer className="modal-card-foot">
                <button className="button is-success" onClick={() => {setVisible(false); doAction()}}>Ok</button>
                <button className="button is-link" onClick={() => setVisible(false)}>Sulje</button>
                </footer>
            </div>
            </div>;
    }

    static ConfirmationWithReason = (title, reason, setReason, buttonText, visible, setVisible, doAction) => {
        return <div className={`modal ${visible ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={() => {setVisible(false); setReason('')}}></button>
                </header>
                <section className="modal-card-body">
                <input className="input" type="text" value={reason} onChange={(event) => setReason(event.target.value)}></input>
                </section>
                <footer className="modal-card-foot">
                <button className="button is-success" onClick={() => {setVisible(false); doAction()}}>{buttonText}</button>
                <button className="button is-link" onClick={() => {setVisible(false); setReason('')}}>Sulje</button>
                </footer>
            </div>
            </div>;
    }

    static ConfirmationWithSecondaryAction = (title, contents, visible, setVisible, primaryActionInfo, secondaryActionInfo) => {
        return <div className={`modal ${visible ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" aria-label="close" onClick={() => setVisible(false)}></button>
                </header>
                <section className="modal-card-body">
                {contents}
                </section>
                <footer className="modal-card-foot">
                <button className={`button ${primaryActionInfo.style}`} onClick={() => {setVisible(false); primaryActionInfo.action()}}>{primaryActionInfo.caption}</button>
                <button className={`button ${secondaryActionInfo.style}`} onClick={() => {setVisible(false); secondaryActionInfo.action()}}>{secondaryActionInfo.caption}</button>
                <button className="button is-link" onClick={() => setVisible(false)}>Sulje</button>
                </footer>
            </div>
            </div>;
    }
}

export default Dialogs;
