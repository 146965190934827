import './RuviObsRequestsAnswered.css';

import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import * as RuviConstants from './RuviConstants';
import RuviObsResult from './RuviObsResult';
import RuviObsStatus from './RuviObsStatus';
import RuviObsReason from './RuviObsReason';

const RuviObsRequestsAnswered = ({history, selvityspyynnot, selectedId, setSelectedId, setSelvityspyyntotyyppi}) => {

    console.log("RuviObsRequestsAnswered, selvityspyynnot: ", selvityspyynnot);
    setSelvityspyyntotyyppi(RuviConstants.RUVIOBSANSWERED);

    const [activeTab, setActiveTab] = useState("");
    const [tabList, setTablist] = useState([]);

    useEffect(() => {
        if (selvityspyynnot && selvityspyynnot.length > 0 && tabList.length === 0) {
            let tabListTemp = selvityspyynnot && selvityspyynnot.map((selvityspyynto) => (selvityspyynto.vuosi))
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a,b) => (a-b))
                .map(vuosi => (
                    {
                        name: vuosi.toString()
                    }
                )
            )
            setActiveTab(tabListTemp[tabListTemp.length-1].name);
            setTablist(tabListTemp);
        }
    }, [selvityspyynnot, tabList])

    const ActiveTabContent = (props) => <>{props.content}</>;

    const changeActiveTab = (tab) => {
        setActiveTab(tab);
    }

    const getActiveTabContent = () => {
        const activeIndex = tabList.findIndex((tab) => {
            return tab.name === activeTab;
        });

        return (
            activeIndex >= 0 && selvityspyynnot && selvityspyynnot.filter(selvityspyynto => (selvityspyynto.vuosi.toString() === tabList[activeIndex].name))
                .sort((a,b) => (new Date(a.viimeinenvastauspvm) - new Date(b.viimeinenvastauspvm)))
                .map((selvityspyynto) => (
                <tr key={`row-${selvityspyynto.peruslohkotunnus}-${selvityspyynto.kasvulohkotunnus}`} onClick = {() => {setSelectedId(selvityspyynto.id); history.push('/obsrequestanswered')}}>
                    <td key={`info-${selvityspyynto.peruslohkotunnus}-${selvityspyynto.kasvulohkotunnus}`}>
                        <span>{`${selvityspyynto.peruslohkonimi} (${selvityspyynto.peruslohkotunnus} ${selvityspyynto.kasvulohkotunnus})`}</span><br/>
                        <span>Vastattu: <Moment format={`${RuviConstants.RUVITIMEFORMAT}`}>{`${selvityspyynto.vastattupvm}`}</Moment></span><br/>
                        <span>Status: {RuviObsStatus.getStatusText(selvityspyynto.statuskoodi)}</span><br/>
                    </td>
                </tr>
            ))
        );
    }

    const Tab = (props) => {
        const { name } = props.tab;
        const { activeTab, changeActiveTab } = props;

        return (
            <li onClick={() => changeActiveTab(name)}>
            <button className={`button is-white ${name === activeTab && "is-active"}`}>
                { /* <span className="icon is-small"><i className="fa fa-image"></i></span> */ }
                <span>{name}</span>
            </button>
            </li>
        );
    };

    const Tabs = (props) => { 
        return (
            <div className="tabs">
                <ul>
                { props.tabList.map(tab => 
                    <Tab tab={tab}
                        key={tab.name}
                        activeTab={props.activeTab}
                        changeActiveTab={props.changeActiveTab}
                    />
                )}
                </ul>
            </div>
        );
    }

    return (
        <div id='ruvi-obsrequestsanswered' className="field">
                <nav className="navbar is-fixed-top">
                    <div className="rows">
                    <div className="row has-text-centered">
                        <h1 className="title">Vastatut selvityspyynnöt</h1>
                    </div>
                    <div className="row">
                        <div className="columns is-centered">
                            <div className="column is-half">
                                <Tabs tabList={tabList}
                                    activeTab={activeTab}
                                    changeActiveTab={changeActiveTab}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </nav>    
                <div id="obs-list-ans" className="rows">
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="hero">
                                <table className="table">
                                    <tbody>
                                    <ActiveTabContent key={activeTab} content={getActiveTabContent()} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar is-fixed-bottom">
                <div className="rows">
                    <div className="row">
                        <div className="columns is-centered">
                            <div className="column">
                                <button className="button is-fullwidth is-warning are-large" onClick={() => {setSelectedId(-1); history.push('/map')}}>Näytä kaikki kartalla</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns is-centered">
                            <div className="column">
                                <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Takaisin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default RuviObsRequestsAnswered;