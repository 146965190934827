import React, { Component } from 'react';
import './RuviCamera.css';

class RuviCamera extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: null,
      stream: null,
      display: {
        width: 0,
        height: 0
      },
      allowphoto: true,
      x: 50,
      y: 50,
      error: null
    };

    // register event handler functions
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleTakePhoto = this.handleTakePhoto.bind(this);
    this.handleOrientation = this.handleOrientation.bind(this);
  }

  async componentDidMount() {
    // add event listeners
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('deviceorientation', this.handleOrientation);

    // get media devices (and save to state if we want to list them??)
    let devices = await this.getDevices();
    this.setState({devices: devices});

    // init device stream with specific resolution
    let stream = await this.initDeviceStreamWithResolution(this.state.stream, window.innerWidth, window.innerHeight);
    await this.useDeviceStream(stream);
    this.setState({stream: stream});
  }

  async componentWillUnmount() {
    // remove event listeners
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('deviceorientation', this.handleOrientation);
  }

  async getDevices() {
    let devices = [];

    try {
      devices = await navigator.mediaDevices.enumerateDevices();

      for (let idx in devices) {
        devices[idx].id = idx;
      }
    } catch (err) {
      this.setState({error: err.message});
    }

    return devices;
  }

  async getDeviceStream(constraints) {
    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      this.setState({error: err.message});
    }

    return stream;
  }

  async initDeviceStreamWithResolution(stream, width, height) {
    // stop old stream if exists
    if (stream != null) {
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    // get the rear camera video stream & use it, specfiy resolution with aspect-ratio
    let props_video = this.props.video || {};
    stream = await this.getDeviceStream({
      audio: false,
      video: {
        width: {
          min:    props_video.widthMin || 640,
          ideal:  props_video.widthIdeal || 1280,
          max:    props_video.widthMax || 1920
        },
        height: {
          min:    props_video.heightMin || 400,
          ideal:  props_video.heightIdeal || 720,
          max:    props_video.heightMax || 1080
        },
        aspectRatio: width / height,
        facingMode: 
          'environment'
        ,
        autoplay: '',
        muted: '',
        playsinline: '',
      }
    });

    // resize video element
    this.setState({
      display: {
        width: width,
        height: height
      }
    });

    return stream;
  }

  async useDeviceStream(stream) {
    // init device stream display element
    let element_video = document.getElementById('device_stream_display');
    element_video.srcObject = stream;
    element_video.setAttribute('playsinline', true);

    // start playing the device stream video
    await element_video.play();
  }

  async handleWindowResize(evt) {
    if (this.state.display.width === window.innerWidth && this.state.display.height === window.innerHeight)
      return;
    
    // re-init device stream with specific resolution
    let stream = await this.initDeviceStreamWithResolution(this.state.stream, window.innerWidth, window.innerHeight);
    await this.useDeviceStream(stream);
    this.setState({stream: stream});
  }

  async handleOrientation(evt) {
    let portrait = this.props.size.width < this.props.size.height;
    //console.log("evt.beta:", evt.beta, ", evt.gamma:", evt.gamma);
    if (!evt.beta && !evt.gamma) { // sensors not available
      return;
    }
    if (portrait) {
      if (Math.abs(evt.beta) >= 70 && Math.abs(evt.beta) <=110) {
        console.log("1");
        this.setState({ allowphoto: true, x: 45 , y: (evt.beta + (evt.beta < 0 ? 135 : -45)) / 45 * 50 -5});
      }
      else {
        console.log("2");
        this.setState({ allowphoto: false, x: 45, y: (evt.beta + (evt.beta < 0 ? 135 : -45)) / 45 * 50 -5});
      }
    }
    else {
      if (Math.abs(evt.gamma) >= 70 && Math.abs(evt.gamma) <= 110 ) {
        console.log("3");
        this.setState({ allowphoto: true, x: 45, y: (evt.gamma + (evt.gamma < 0 ? 135 : -45)) / 45 * 50 -5});
      } else {
        console.log("4");
        this.setState({ allowphoto: false, x: 45, y: (evt.gamma + (evt.gamma < 0 ? 135 : -45)) / 45 * 50 -5});
      }
    }
  }

  async handleTakePhoto(evt) {
    if (!this.state.allowphoto) {
      return;
    }

    let element_video = document.getElementById('device_stream_display');
    let element_canvas = document.getElementById('device_photo_memory');

    // copy memory from device stream to our internal canvas
    let ctx = element_canvas.getContext('2d');

    if (element_video.videoWidth && element_video.videoHeight) {
      element_canvas.width = element_video.videoWidth;
      element_canvas.height = element_video.videoHeight;

      ctx.drawImage(element_video, 0, 0, element_video.videoWidth, element_video.videoHeight);

      // send onPhotoTaken function call if it exists in props
      if (this.props.onPhotoTaken) {
        this.props.onPhotoTaken(element_canvas.toDataURL());
      }
    }
  }

  render() {
    console.log("X:", this.state.x, ", Y:", this.state.y);
    //console.log("allowphoto:", this.state.allowphoto);
    return (
      <div className="camera">
        <button className={`button btn-take-photo ${this.state.allowphoto ? "is-success" : "is-danger"}`} onClick={this.handleTakePhoto}>{`Ota Kuva`}</button>
        <button id='cancel' className={`button is-danger`} onClick={() => {this.props.history.goBack();}}>Peruuta</button> 
        {/*!this.state.allowphoto &&
          <div className="message has-text-centered">
            <span className="has-text-danger has-text-weight-bold">Pidäthän puhelimesi vaakatasossa ottaaksesi kuvan</span>
          </div>
        */}
        <div className="shooter is-danger" style={{left: '' + (this.state.x - 2) + '%' , top: '' + (this.state.y - 5) + '%'}}>
            <span style={{display: ( this.state.allowphoto  || this.state.y > 40 ?  'none' : '')}}><i className={`fas fa-5x fa-caret-down has-text-danger`}></i></span>
            <span style={{display: ( !this.state.allowphoto || this.state.y > 40 ?  'none' : '')}}><i className={`fas fa-5x fa-caret-down has-text-warning`}></i></span>
            <span style={{display: ( !this.state.allowphoto || this.state.y < 60 ?  'none' : '')}}><i className={`fas fa-5x fa-caret-up has-text-warning`}></i></span>
            <span style={{display: ( this.state.allowphoto || this.state.y < 60 ?  'none' : '')}}><i className={`fas fa-5x fa-caret-up has-text-danger`}></i></span>
        </div>
        <video id="device_stream_display"></video>
        <canvas id="device_photo_memory"></canvas>
      </div>
    );
  }
}

export default RuviCamera;
