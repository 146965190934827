import './RuviObsRequests.css';

import React from 'react';
import Moment from 'react-moment';
import * as RuviConstants from './RuviConstants';
import RuviObsStatus from './RuviObsStatus';
import RuviObsReason from './RuviObsReason';

const RuviObsRequests = ({history, selvityspyynnot, selectedId, setSelectedId, setSelvityspyyntotyyppi}) => {

    console.log("RuviObsRequests, selvityspyynnot: ", selvityspyynnot);
    setSelvityspyyntotyyppi(RuviConstants.RUVIOBSNOTANSWERED);

    return (
        <div id='ruvi-obsrequests' className="field">
            <div id="obs-list" className="rows">
                <nav className="navbar is-fixed-top">
                    <div className="row has-text-centered">
                        <h1 className="title">Valitse selvityspyyntö</h1>
                    </div>
                </nav>    
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="hero">
                                <table className="table">
                                    <tbody>
                                    { selvityspyynnot && selvityspyynnot.sort((a,b) => (new Date(a.viimeinenvastauspvm) - new Date(b.viimeinenvastauspvm))).map((selvityspyynto) => (
                                        <tr key={`row-${selvityspyynto.peruslohkotunnus}-${selvityspyynto.kasvulohkotunnus}`} onClick = {() => {setSelectedId(selvityspyynto.id); history.push('/obsrequest')}}>
                                            <td key={`info-${selvityspyynto.peruslohkotunnus}-${selvityspyynto.kasvulohkotunnus}`}>
                                                <span>{`${selvityspyynto.peruslohkonimi} (${selvityspyynto.peruslohkotunnus} ${selvityspyynto.kasvulohkotunnus})`}</span><br/>
                                                <span>Määräaika: <Moment format={`${RuviConstants.RUVITIMEFORMAT}`}>{`${selvityspyynto.viimeinenvastauspvm}`}</Moment></span><br/>
                                                <span>Status: {RuviObsStatus.getStatusText(selvityspyynto.statuskoodi)}</span><br/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar is-fixed-bottom">
                <div className="rows">
                    <div className="row">
                        <div className="columns is-centered">
                            <div className="column">
                                <button className="button is-fullwidth is-success are-large" onClick={() => {setSelectedId(-1); history.push('/map')}}>Näytä selvityspyynnöt kartalla</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns is-centered">
                            <div className="column">
                                <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Takaisin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default RuviObsRequests;