import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import translations from './translations';
import './App.css';
import RuviMap from './RuviMap';
import RuviOnMap from './RuviOnMap';
import RuviStart from './RuviStart';
import RuviHelp from './RuviHelp';
import RuviObsRequests from './RuviObsRequests';
import RuviObsRequest from './RuviObsRequest';
import RuviObsRequestsAnswered from './RuviObsRequestsAnswered';
import RuviObsRequestAnswered from './RuviObsRequestAnswered';
import RuviObsRequestAnswer from './RuviObsRequestAnswer';
import RuviObsRequestInfo from './RuviObsRequestInfo';
import * as RuviConstants from './RuviConstants';
import ServiceApi from './ServiceApi';
import Loading from './Loading';

const i18n = i18next.createInstance();
i18n.init({
  lng: 'fi',
  resources: translations,
});

if (window.serviceEnv && window.serviceEnv.startsWith('_REPLACE')) {
  window.serviceEnv = process.env.REACT_APP_SERVICE_ENV;
}
if (window.apiUrl && window.apiUrl.startsWith('_REPLACE')) {
  window.apiUrl = process.env.REACT_APP_API_URL;
}
if (window.wmtsUrl && window.wmtsUrl.startsWith('_REPLACE')) {
  window.wmtsUrl = process.env.REACT_APP_WMTS_URL;
}

function App() {

  // not as state to avoid continous rendering
  let coordinates = [];
  const setCoordinates = (coords) => {coordinates = coords;}
  const getCoordinates = () => {return coordinates;}

  const size = useWindowSize();
  const [tilatoken, setTilatoken] = useState(null);
  const [vastattavialLkm, setVastattavialLkm] = useState(null);
  const [kasvikoodi, setKasvikoodi] = useState(null);
  const [selvityspyynnot, setSelvityspyynnot] = useState([]);
  const [selvityspyynnotvastatut, setVastatutSelvityspyynnot] = useState([]);
  const [selectedId, setSelectedId] = useState(0)
  const [selvityspyyntotyyppi, setSelvityspyyntotyyppi] = useState(0)
  const [image, setImage] = useState(null)
  const [lahetystiedot, setLahetystiedot] = useState({})
  const [capabilities,setCapabilities] = useState(null);
  
  // useEffect(() => {
  //  tilatoken && ServiceApi.getInit(tilatoken, setVastattavialLkm, setTilatoken);
  // },
  // [tilatoken]);


  useEffect(() => {
    if (tilatoken && vastattavialLkm && vastattavialLkm >0 ) {
       //ServiceApi.getKasvikoodi(setKasvikoodi);
       ServiceApi.getSelvityspyynnot(tilatoken, setSelvityspyynnot);
       ServiceApi.getVastatutSelvityspyynnot(tilatoken, setVastatutSelvityspyynnot);
       ServiceApi.getCapabilities(setCapabilities);
    }
  }, [vastattavialLkm, tilatoken]);

  const loadData = () => {
    ServiceApi.getSelvityspyynnot(tilatoken, setSelvityspyynnot);
    ServiceApi.getVastatutSelvityspyynnot(tilatoken, setVastatutSelvityspyynnot);
  }

  console.log('tilatoken', tilatoken);
  console.log('vastattavialLkm', vastattavialLkm);
  console.log('selvityspyynnot', selvityspyynnot);

  const Init= ({ history, match }) => (
    <Loading history={history} match={match} tilatoken={tilatoken} setTilatoken={setTilatoken} setVastattavialLkm={setVastattavialLkm} ></Loading>
  );

  const Help= ({ history, match }) => (
    <RuviHelp history={history} match={match} size={size}></RuviHelp>
  );

  const App= ({ history }) => (
    <RuviStart history={history} size={size}></RuviStart>
  );

  const ObsRequests= ({ history }) => (
    <RuviObsRequests history={history} size={size} selvityspyynnot={selvityspyynnot} selectedId={selectedId} setSelectedId={setSelectedId} setSelvityspyyntotyyppi={setSelvityspyyntotyyppi}></RuviObsRequests>
  );

  const ObsRequest= ({ history }) => (
    <RuviObsRequest history={history} size={size} selvityspyynnot={selvityspyynnot} selectedId={selectedId} setSelectedId={setSelectedId}></RuviObsRequest>
  );

  const ObsRequestsAnswered= ({ history, match }) => (
    <RuviObsRequestsAnswered history={history} size={size} selvityspyynnot={selvityspyynnotvastatut} setSelectedId={setSelectedId} selectedId={selectedId} setSelvityspyyntotyyppi={setSelvityspyyntotyyppi}></RuviObsRequestsAnswered>
  );

  const ObsRequestAnswered= ({ history }) => (
    <RuviObsRequestAnswered history={history} size={size} selvityspyynnot={selvityspyynnotvastatut} selectedId={selectedId} setSelectedId={setSelectedId}></RuviObsRequestAnswered>
  );

  const ObsRequestAnswer= ({ history }) => (
    <RuviObsRequestAnswer history={history} size={size} selvityspyynnot={selvityspyynnot} selectedId={selectedId} setSelectedId={setSelectedId} lahetystiedot={lahetystiedot}></RuviObsRequestAnswer>
  );

  const ObsRequestInfo= ({ history }) => (
    <RuviObsRequestInfo history={history} size={size} selvityspyynnot={selvityspyynnot} selectedId={selectedId} setSelectedId={setSelectedId} tilatoken={tilatoken} image={image} setLahetystiedot={setLahetystiedot} loadData={loadData}></RuviObsRequestInfo>
  );

  const AppMap= ({ history }) => (
    <RuviOnMap i18n={i18n} history={history} size={size} selvityspyynnot={selvityspyyntotyyppi === RuviConstants.RUVIOBSNOTANSWERED ? selvityspyynnot : selvityspyynnotvastatut} selectedId={selectedId} setSelectedId={setSelectedId}
    setImage={setImage} selvityspyyntotyyppi={selvityspyyntotyyppi} getCoordinates={getCoordinates} ></RuviOnMap>
  );

  const Unauthorized = ( { history}) => (
     <h1>Unauthorized</h1> 
  );

  return (
    <div className="App">
      {capabilities && tilatoken && <RuviMap i18n={i18n} size={size} capabilities={capabilities} selvityspyynnot={selvityspyyntotyyppi === RuviConstants.RUVIOBSNOTANSWERED ? selvityspyynnot : selvityspyynnotvastatut} selectedId={selectedId} setSelectedId={setSelectedId}
        selvityspyyntotyyppi={selvityspyyntotyyppi} setCoordinates={setCoordinates}></RuviMap>}
      <Router>
        <Switch>
          {!tilatoken && <Route path='/:token' render={Init} />}
          {!tilatoken && <Route render={ Unauthorized} /> }
          <Route path='/map' render={AppMap} />
          <Route path='/start' render={App} />
          <Route path='/help/:page' render={Help} />
          <Route path='/obsrequests' render={ObsRequests} />
          <Route path='/obsrequest' render={ObsRequest} />
          <Route path='/obsrequestsanswered' render={ObsRequestsAnswered} />
          <Route path='/obsrequestanswered' render={ObsRequestAnswered} />
          <Route path='/obsrequestanswer' render={ObsRequestAnswer} />
          <Route path='/obsrequestinfo' render={ObsRequestInfo} />
          {/* <Route path='/:token' render={Init} /> */}
          <Route render={() => <Redirect to='/start' />} />
        </Switch>
      </Router>
    </div>
  );
}

//Hook

const isClient = typeof window === 'object';

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

function useWindowSize() {

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!(typeof window === 'object')) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
} 

export default App;
