import './RuviObsRequestAnswer.css';

import React from 'react';
import RuviCommon from './RuviCommon';
import Moment from 'react-moment';
import * as RuviConstants from './RuviConstants';

const RuviObsRequestAnswer = ({history, selvityspyynnot, selectedId, setSelectedId, lahetystiedot}) => {

    let selvityspyynto = selvityspyynnot && (selvityspyynnot.filter((selvityspyynto) => (selvityspyynto.id === selectedId)))[0];

    console.log("RuviObsRequestAnswer, selvityspyynto: ", selvityspyynto, selectedId);

    return (
        <div id='ruvi-obsrequestanswer' className="field">
        {selvityspyynto &&
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Selvityksesi on lähetetty</h1>
                </div>
                <div id='ruvi-picture' className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop"> 
                    <figure className="image is-256x256 is-inline-block">
                        <img src={lahetystiedot.kuvatiedosto} alt=''/>
                    </figure>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <span>Vapaateksti: {lahetystiedot.vapaateksti}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <span>Käsittelemme selvityksesi mahdollisimman pian. Lähetämme sinulle viestin, kun selvitys on käsitelty.</span><br/><br/>
                            <span>Käsiteltävä peruslohko: {selvityspyynto.peruslohkonimi} ({selvityspyynto.peruslohkotunnus})</span><br/>
                            <span>Vastaanotettu: <span className="has-text-weight-bold"><Moment format={`${RuviConstants.RUVITIMEFORMAT}`}>{`${lahetystiedot.vastaanotettuPvm}`}</Moment></span></span><br/>
                            <span>Lähetystunnus: <span className="has-text-weight-bold">{lahetystiedot.lahetystunnus})</span></span><br/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {RuviCommon.RuviContact()}
                        </div>
                    </div>
                </div>
                 {/* <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                    <figure className="image is-256x256 is-inline-block">
                        <img src="welcomebg.png" alt="welcome" />
                    </figure>
                </div> */}
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => {setSelectedId(-1); history.push('/start')}}>Siirry etusivulle</button>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    )
}

export default RuviObsRequestAnswer;