const OBS_STATUS_CREATED = 1;
const OBS_STATUS_NOT_ANSWERED = 2;
const OBS_STATUS_ANSWERED = 3;
const OBS_STATUS_HANDLED = 4;

class RuviObsStatus {
    static getStatusText = (result) => {
        switch (result) {
            case OBS_STATUS_CREATED:
                return 'Luotu';
            case OBS_STATUS_NOT_ANSWERED:
                return 'Vastaamatta';
            case OBS_STATUS_ANSWERED:
                return 'Vastattu';
            case OBS_STATUS_HANDLED:
                return 'Käsitelty';
            default:
                return 'N/A'
          }
    }
    static isActive = (result) => {
        return result === OBS_STATUS_NOT_ANSWERED || result === 99 ? true : false;
    }
}

export default RuviObsStatus;
