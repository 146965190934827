const OBS_RESULT_NOT_HANDLED = 1;
const OBS_RESULT_OK = 2;
const OBS_RESULT_UNCLEAR = 3;
const OBS_RESULT_NOT_OK = 4;

class RuviObsResult {
    static getResultText = (result) => {
        switch (result) {
            case OBS_RESULT_NOT_HANDLED:
                return 'Käsittelemätön';
            case OBS_RESULT_OK:
                return 'Kunnossa';
            case OBS_RESULT_UNCLEAR:
                return 'Epäselvä';
            case OBS_RESULT_NOT_OK:
                return 'Ei kunnossa';
            default:
                return 'N/A'
          }
    }
}

export default RuviObsResult;
