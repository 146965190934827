import './RuviHelp.css';

import React from 'react';

const Help1 = ({history}) => (
    <div id='ruvi-help' className="field">
        <div className="rows">
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half is-size-5">
                        Vaihe 1.
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        Kun saat viestin, sinut ohjataan karttanäkymään, jossa näet kohteen oranssina merkkinä kartalla.
                    </div>
                </div>
            </div>
            <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                <figure className="image is-256x256 is-inline-block">
                    <img src="welcomebg.png" alt='welcome'/>
                </figure>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/help/2')}>Seuraava</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Help2 = ({history}) => (
    <div id='ruvi-help' className="field">
        <div className="rows">
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half is-size-5">
                        Vaihe 2.
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        Kulje kartassa merkitylle oranssille pisteelle ottaaksesi kuvan. Sininen ympyrä näyttää sijaintisi.
                    </div>
                </div>
            </div>
            <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                <figure className="image is-256x256 is-inline-block">
                    <img src="welcomebg.png" alt='welcome'/>
                </figure>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/help/3')}>Seuraava</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Help3 = ({history}) => (
    <div id='ruvi-help' className="field">
        <div className="rows">
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half is-size-5">
                        Vaihe 3.
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        Kun olet paikalla, avaa kamera ja näet ruudulla valkean kompassin. Siirrä kamera osoittamaan haluttuun ilmansuuntaan, jolloin kompassi muuttuu vihreäksi.
                        Muista myös pitää puhelin suorassa.
                    </div>
                </div>
            </div>
            <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                <figure className="image is-256x256 is-inline-block">
                    <img src="welcomebg.png" alt='welcome'/>
                </figure>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/help/4')}>Seuraava</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Help4 = ({history}) => (
    <div id='ruvi-help' className="field">
        <div className="rows">
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half is-size-5">
                        Vaihe 4.
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        Olet valmis, kun painat 'Lähetä kuva'-nappia ja saat vahvistusviestin puhelimeesi.
                    </div>
                </div>
            </div>
            <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                <figure className="image is-256x256 is-inline-block">
                    <img src="welcomebg.png" alt='welcome'/>
                </figure>
            </div>
            <div className="row">
                <div className="columns is-centered">
                    <div className="column is-half">
                        <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Aloita sovelluksen käyttö</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const RuviHelp = ({ history, match}) => {
    let page = Help4({history: history})
    if (match.params.page === '1') {
      page = Help1({history: history})
    }
    else  if (match.params.page === '2') {
      page = Help2({history: history})
    }
    else if (match.params.page === '3') {
      page = Help3({history: history})
    }
    return page;
}

export default RuviHelp;