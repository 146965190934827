import WKT from 'ol/format/WKT';
import {getCenter} from 'ol/extent';
import {getLength} from 'ol/sphere';
import LineString from 'ol/geom/LineString';

import './RuviOnMap.css';
import RuviCamera from './RuviCamera';
import DevicePermissions from './DevicePermissions';
import * as RuviConstants from './RuviConstants';

import React, { useState, useEffect } from 'react';

function RuviOnMap({ history, i18n, selvityspyynnot, selectedId, setSelectedId, setImage, selvityspyyntotyyppi, getCoordinates, size}) {

    const inside = [];
    const [coordinates, setCoordinates] = useState([]);
    const [text, setText] = useState('Takaisin');
    const [distanceLabel, setDistanceLabel] = useState('');
    const [locationPermission, setLocationPermission] = useState(true);
    const [inplace, setInPlace] = useState(false);
    const [camera, setCamera] = useState(false);
    const [picture, setPicture] = useState(null);

    var perms = new DevicePermissions();
    perms.checkGeolocationPermission(() => setLocationPermission(true), () => {setLocationPermission(false); alert('Sijainnille ei ole annettu käyttöoikeuksia')});

    const updateCoors = () => {
        let coords = getCoordinates();
        if (selectedId !== -1 && selvityspyynnot.length > 0) {
            let pyynto = selvityspyynnot.filter( pyynto => pyynto.id === selectedId )[0]
            if (pyynto.klgeometria != null || pyynto.plgeometria != null) {
                let parser = new WKT()
                let geom = parser.readGeometry(pyynto.klgeometria ?
                    pyynto.klgeometria : pyynto.plgeometria, {dataProjection : pyynto.proj ? pyynto.proj :'EPSG:3067', featureProjection : 'EPSG:3067'});
                let from = coords;
                let to = getCenter(geom.getExtent());
                let distance = getLength(new LineString( [from,to] ), {projection : 'EPSG:3067'});
                let intersects = geom.intersectsCoordinate(from);
                let coordinatesnow = coordinates;
                coordinatesnow.push(coords)
                if (coordinatesnow.length > 10) coordinatesnow.shift(); // max 10
                setCoordinates(coordinatesnow);
                inside.push(intersects)
                if (inside.length >16) inside.shift(); // max 16
                //console.log(inside.length, inside);
                if (inside.filter(i => i).length > (inplace ? 5 : 10)) {
                    setInPlace(true);
                    setText('Avaa kamera');
                }
                else {
                    setInPlace(false);
                    setDistanceLabel("Etäisyys kohteesta " + Math.round(distance,0) +'m');
                    setText("Takaisin");
                }
            }
        }
    };

    const handleClick = () => {
        if (!inplace) { 
            /*if (selectedId === -1) {
                switch (selvityspyyntotyyppi) {
                    case RuviConstants.RUVIOBSNOTANSWERED:
                        history.push('/obsrequests');
                        break;
                    case RuviConstants.RUVIOBSANSWERED:
                        history.push('/obsrequestsanswered');
                        break;
                    default:
                        history.push('/start');
                }
            } else {
                switch (selvityspyyntotyyppi) {
                    case RuviConstants.RUVIOBSNOTANSWERED:
                        history.push('/obsrequest');
                        break;
                    case RuviConstants.RUVIOBSANSWERED:
                        history.push('/obsrequestanswered');
                        break;
                    default:
                        history.push('/start');
                }
            }*/
            history.goBack();
        }
        else if (locationPermission) {
            setCamera(true);
            perms.checkCameraPermission(() => setCamera(true), () => {setCamera(false); alert('Kameralle ei ole annettu käyttöoikeuksia')});
        }
    }

    const handlePhoto = (data, compass) => {
        console.log('handlePhoto');
        console.log("position", getCoordinates());
        setPicture(data);
        setCamera(false)
    }

    // Mount
     useEffect(() => {
        console.log('on map mount')
        let timer = setInterval(updateCoors, 500);        
        return () => {
            console.log('on map unmount')
            // Unmount
            clearInterval(timer);
        };
        // eslint-disable-next-line
    }, []);
  
    return (
        <div id='ruvi-onmap'>
            {!picture && <div id='map-back'>
                {!inplace && <div id='distance' className="box">
                    {distanceLabel}
                </div>}
                <button className={`button is-fullwidth ${inplace ? 'is-success' : 'is-warning'} are-large`} 
                    onClick={() => handleClick()} >{text}</button>
            </div>}
            <div id='help' className='box'>{inplace ? 'Sinun täytyy ottaa kuva kartalla korostetulta lohkolta': 'Sinun täytyy siirtyä kartalla korostetulle lohkolle, voidaksesi ottaa kuvan siitä'} </div>
            {camera && inplace && <div id = 'camera'> 
                <RuviCamera video={{
                    widthMin: 640,
                    widthIdeal: 1280,
                    widthMax: 1920,
                    heightMin: 400,
                    heightIdeal: 720,
                    heightMax: 1080
                }} onPhotoTaken={(data) => handlePhoto(data)}
                size={size}
                history={history}/>
            </div> }
            {picture && <div id = 'save-photo'> 
                <img id="photo" src={picture} alt='figure'></img>
                <button id='take-again' className={`button is-danger`} onClick={() => {setCamera(true); setPicture(null)}}>Peruuta</button> 
                <button id='save-button' className={`button is-success`} onClick={() => {setImage({picture: picture, coordinates: coordinates}); setPicture(null); history.push('/obsrequestinfo')}}>Tallenna</button> 
            </div> }            
        </div>
    );
}

export default RuviOnMap;
