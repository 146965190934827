const OBS_REASON_PLANTDETECT = 1;
const OBS_REASON_PLANTCUT_ = 2;

class RuviObsReason {
    static getReasonText = (result) => {
        switch (parseInt(result)) {
            case OBS_REASON_PLANTDETECT:
                return 'Kasvin tunnistus';
            case OBS_REASON_PLANTCUT_:
                return 'Niittovaatimus';
            default:
                return 'N/A'
          }
    }
}

export default RuviObsReason;
