import { Control } from 'ol/control';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import { Fill, Stroke, Circle, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Point from 'ol/geom/Point';
import Kompas from 'kompas';

export default class GeoLocationControl extends Control {

    constructor(proj, centerMap, setLocation ) {
        const Status = {
            Off: 0,
            ShowLocation: 1,
            CenterLocation: 2,
        }
    
        let geolocationStatus = Status.ShowLocation;

        var geolocation = new Geolocation({
            // enableHighAccuracy must be set to true to have the heading value.
            trackingOptions: {
                enableHighAccuracy: true
            },
            projection: proj
        });
    
        // handle geolocation error.
        geolocation.on('error', function (error) {
            console.log('geolocation error', error.message);
        });
    
        geolocation.on('change:position', function () {
            var coordinates = geolocation.getPosition();
            setLocation(coordinates);
            positionFeature.setGeometry(coordinates ?
                new Point(coordinates) : null);
            if (geolocationStatus === Status.CenterLocation) {
                 centerMap(coordinates);
            }
        });
        
        var accuracyFeature = new Feature();
        geolocation.on('change:accuracyGeometry', function () {
            accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
        });
    
        var positionFeature = new Feature();
        positionFeature.setStyle(new Style({
            image: new Circle({
                radius: 6,
                fill: new Fill({
                    color: '#3399CC'
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 2
                })
            })
        }));
    
        const geolocationLayerPos = new VectorLayer({
            source: new VectorSource({
                features: [positionFeature,accuracyFeature]
            }),
        });
    
        let toggleselected = () => {};

        const btnListener = (evt) => {
            const map = this.getMap();
            if (geolocationStatus === Status.CenterLocation){
                geolocationLayerPos.setMap(map);
                geolocationStatus = Status.ShowLocation;
            }
            else if (geolocationStatus === Status.ShowLocation){
                geolocationLayerPos.setMap(map);
                geolocationStatus = Status.CenterLocation;
            }
            toggleselected()

        };

        let btnIcon = document.createElement('i');
        btnIcon.className = "fas fa-sm fa-crosshairs";

        let btn = document.createElement('button');
        btn.type = 'button';
        btn.addEventListener('click', btnListener);
        btn.appendChild(btnIcon);

        let classNames = 'ol-geolocation ol-control ol-unselectable';

        let customEl = document.createElement('div');
        customEl.className = classNames;
        customEl.appendChild(btn);

        toggleselected = () => {
            btn.className = geolocationStatus=== Status.ShowLocation ? '' :'ol-geolocation-on'
        }

        geolocation.setTracking(true);
        super({element: customEl});
        this.layer = geolocationLayerPos;
    }

    start() {
        const map = this.getMap();

        const compass = new Kompas();
        compass.watch();

        compass.on('heading', function(heading) {
            //const view = map.getView();
            //view.setRotation(Math.PI / 180 * heading);
        });

        this.layer.setMap(map);
    }
}
