import './RuviObsRequestInfo.css';

import React, { useState } from 'react';
import RuviCommon from './RuviCommon';
import ServiceApi from './ServiceApi';
import Dialogs from './Dialogs';

const RuviObsRequestInfo = ({history, selvityspyynnot, selectedId, setSelectedId, tilatoken, image, setLahetystiedot,loadData}) => {
    let selvityspyynto = selvityspyynnot && (selvityspyynnot.filter((selvityspyynto) => (selvityspyynto.id === selectedId)))[0];

    const [vapaateksti, setVapaateksti] = useState("")
    const [virheselite, setVirheselite] = useState(null)
    const [infoikkuna, setInfoikkuna] = useState(false);

    console.log("RuviObsRequestInfo, selvityspyynto: ", selvityspyynto, selectedId);

    const save = () => {
        console.log('save picture', image);
        ServiceApi.saveObsResponse(tilatoken,
            {
                "tallennustoken": selvityspyynto.tallennusToken,
                "kuvatiedosto": image.picture,
                "kuvienlkm": 1,
                "vapaateksti": vapaateksti
            }
        ).then((resp) => {
            console.log("resp", resp);
            if (resp.tallennusOk) {
                setLahetystiedot(
                    {
                        "lahetystunnus": resp.lahetystunnus,
                        "vastaanotettuPvm": resp.vastaanotettuPvm,
                        'kuvatiedosto': image.picture,
                        "vapaateksti": vapaateksti
                    });
                loadData();
                history.push('/obsrequestanswer');
            } else {
                setVirheselite(resp.virheSelite);
                setInfoikkuna(true);
            }
        })
        .catch((error) => {
            if (error.response) {
                console.log("error", error.response);
                setVirheselite(error.response.data.virheSelite);
                setInfoikkuna(true);
            }
            else {
                setVirheselite("Tallennus epäonnistui");
                setInfoikkuna(true);
            }
        })
    }

    return (
        <div id='ruvi-obsrequestinfo' className="field">
            {Dialogs.Information("Tallennus epäonnistui", virheselite && <>
                <div>Virheselite: {virheselite}</div>
                <div>Yritä tallennusta tarvittaessa uudelleen.</div>
                    </>, infoikkuna, setInfoikkuna)}
            {selvityspyynto &&
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Lisää vapaamuotoinen teksti selvityspyyntöön</h1>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <textarea className="textarea" placeholder="" rows="10" value={vapaateksti} onChange={(event) => setVapaateksti(event.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                <div id='ruvi-picture' className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop"> 
                    <figure className="image is-256x256 is-inline-block">
                        <img src={image.picture} alt=''/>
                    </figure>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {RuviCommon.RuviContact()}
                        </div>
                    </div>
                </div>
                 {/* <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                    <figure className="image is-256x256 is-inline-block">
                        <img src="welcomebg.png" alt="welcome" />
                    </figure>
                </div> */}
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => save()}>Lähetä vastaus</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Peruuta</button>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    )
}

export default RuviObsRequestInfo;