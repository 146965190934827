import './RuviObsRequest.css';

import React from 'react';
import Moment from 'react-moment';
import * as RuviConstants from './RuviConstants';
import RuviCommon from './RuviCommon';
import RuviObsStatus from './RuviObsStatus';
import RuviObsReason from './RuviObsReason';

const RuviObsRequest = ({history, selvityspyynnot, selectedId, setSelectedId}) => {

    let selvityspyynto = selvityspyynnot && (selvityspyynnot.filter((selvityspyynto) => (selvityspyynto.id === selectedId)))[0];

    console.log("RuviObsRequest, selvityspyynto: ", selvityspyynto, selectedId);

    return (
        <div id='ruvi-obsrequest' className="field">
        {selvityspyynto &&
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Tietoa selvityspyynnöstä</h1>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <span>Keräämme tietoa seuraavasta kohteesta.</span><br/><br/>
                            <span>Maatilatunnus: {selvityspyynto.tilatunnus}</span><br/>
                            <span>Peruslohko: {selvityspyynto.peruslohkonimi} ({selvityspyynto.peruslohkotunnus} {selvityspyynto.kasvulohkotunnus})</span><br/>
                            <span>Kasvi: {selvityspyynto.kasvikoodi} - {selvityspyynto.kasvinimiFi}</span><br/>
                            <span>Selvityspyynnön syy: {RuviObsReason.getReasonText(selvityspyynto.selvityspyyntosyy)}</span><br/>
                            <span>Status: {RuviObsStatus.getStatusText(selvityspyynto.statuskoodi)}</span><br/><br/>
                            <span>Vastaus on lähetettävä viimeistään: <span className="has-text-weight-bold"><Moment format={`${RuviConstants.RUVITIMEFORMAT}`}>{`${selvityspyynto.viimeinenvastauspvm}`}</Moment></span></span><br/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {RuviCommon.RuviContact()}
                        </div>
                    </div>
                </div>
                 {/* <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                    <figure className="image is-256x256 is-inline-block">
                        <img src="welcomebg.png" alt="welcome" />
                    </figure>
                </div> */}
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => {history.goBack();}}>Takaisin</button>
                        </div>
                    </div>
                </div>
                {selvityspyynto && RuviObsStatus.isActive(selvityspyynto.statuskoodi) &&
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => history.push('/map')}>Aloita ilmoittaminen</button>
                        </div>
                    </div>
                </div>}
            </div>
        }
        </div>
    )
}

export default RuviObsRequest;