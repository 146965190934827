import React, {useEffect} from 'react';
import ServiceApi from './ServiceApi';

const Loading = ({ history, match, tilatoken, setTilatoken, setVastattavialLkm }) => {

    console.log(match.params.token);
   
    ServiceApi.getInit(match.params.token, setVastattavialLkm, setTilatoken, history);
    history.push('/');

    return <h1>loading...</h1>;
}

export default Loading;