import './RuviStart.css';

import React from 'react';

const RuviStart = ({history}) => {

    return (
        <div id='ruvi-start' className="field">
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Tervetuloa Ruokaviraston nnn-sovellukseen</h1>
                    <h2 className="subtitle is-size-6">Sovelluksen avulla vastaat maataloushallinnon lähettämiin selvityspyyntöihin</h2>
                </div>
                <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                    <figure className="image is-256x256 is-inline-block">
                        <img src="welcomebg.png" alt='welcome'/>
                    </figure>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/help/1')}>Näytä ohjeet</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/obsrequestsanswered')}>Näytä aikaisemmat selvityspyynnöt</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => history.push('/obsrequests')}>Vastaa selvityspyyntöihin</button>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => history.push('/map')}>Näytä pyynnöt kartalla</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default RuviStart;